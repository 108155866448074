.item-container-unit {
    display: block;
    cursor: pointer;
}
#batch-operations .item-container-unit {
    cursor: default;
}
#batch-operations label.item-container-unit{
    cursor: pointer;
}
#batchEditDates label.item-container-unit {
    display: inline-block;
}
.fake-input-container {
    position: relative;
    display: block;
    float: left;
    height: 16px;
    width: 16px;
    cursor: pointer;
}
.done-checkbox {
    padding: 7px;
    height: 16px;
    width: 16px;
}
.batch-item-selector-wrapper .fake-input-container {
    padding: 23px 0;
}
div.condition-item-container .item-container-unit .fake-input-container,
.content-unit.Customer .people-dropdown div.condition-item-container .item-container-unit .fake-input-container,
.content-unit.ProjectOverloadResolution .people-dropdown div.condition-item-container .item-container-unit .fake-input-container,
.content-unit.Project .people-dropdown div.condition-item-container .item-container-unit .fake-input-container,
.content-unit.Portfolio .people-dropdown div.condition-item-container .item-container-unit .fake-input-container,
.content-unit .people-dropdown div.condition-item-container.ListCustomField .item-container-unit .fake-input-container {
    margin: 1px 7px 1px 0;
}
.fake-input-container.disabled {
    cursor: default;
    pointer-events: none;
}
.fake-input-container input {
    display: none;
}
.fake-input-container .fake-input,
input.checkbox[type="checkbox"] + .fake-input{
    display: block;
    width: 18px;
    height: 18px;
    background: url("./Images/elements/custom-checkbox.svg") no-repeat -2px -2px;
    vertical-align: text-top;
}

.item-container-unit .fake-input-container .fake-input:focus,
.item-container-unit input.checkbox[type="checkbox"] + .fake-input:focus {
    background: url("./Images/elements/custom-checkbox.svg") no-repeat -42px -2px;
}

.item-container-unit .fake-input-container input.checkbox:checked + .fake-input:focus,
.item-container-unit .fake-input-container.checked input.checkbox + .fake-input:focus,
.item-container-unit .fake-input-container.checked .fake-input:focus {
    background: url("./Images/elements/custom-checkbox.svg") no-repeat -62px -2px;
}

.fake-input-container.checked input.checkbox + .fake-input,
.fake-input-container.checked .fake-input,
input.checkbox:checked[type="checkbox"] + .fake-input {
    background: url("./Images/elements/custom-checkbox.svg") no-repeat -22px -2px;
}

.fake-input-container.disabled .fake-input,
.fake-input-container.disabled input.checkbox + .fake-input,
.fake-input-container.disabled + .cust-checkbox-text {
    opacity: .4;
}

.fake-input-container + .fake-input {
    display: none;
    opacity: 0;
    visibility: hidden;
}

#batch-operations .fake-input-container {
    margin-right: 6px;
}
#batch-operations .item-container-unit {
    min-height: 26px;
}
#batch-operations .fake-input-container, #batch-operations .fake-input-container .fake-input {
    height: 26px;
}




#batch-operations .fake-input-container.add .fake-input,
#batch-operations .fake-input-container.remove .fake-input,
#batch-operations .item-container-unit .fake-input-container.add .fake-input,
#batch-operations .item-container-unit .fake-input-container.remove .fake-input {
    background-color: #E6E6E6;
    background-image: url("./Images/ActivityList/add-remove-checkboxes.png");
    height: 17px;
    width: 17px;
}
#batch-operations .fake-input-container.add .fake-input {
    background-position: 0 0;
}

#batch-operations .fake-input-container.remove .fake-input {
    background-position: -17px 0;
}
#batch-operations .fake-input-container.checked.add .fake-input,
#batch-operations .fake-input-container.checked.remove .fake-input {
    background-color: #4e7eff;
}
#batch-operations .fake-input-container.checked.add .fake-input {
    background-position: 0 -17px;
}
#batch-operations .fake-input-container.checked.remove .fake-input {
    background-position: -17px -17px;
}

#batch-operations .fake-input-container .fake-input {
    background-position: -1px 3px;
}
#batch-operations .fake-input-container.checked .fake-input {
    background-position: -21px 3px;
}
#assignee-list-container .list-title-group-container .fake-input-container .fake-input,
#assignee-list-container .list-title-group-container input.checkbox[type="checkbox"] + .fake-input {
    display: block;
    width: 16px;
    height: 16px;
    background: transparent;
    vertical-align: text-top;
}
#assignee-list-container .list-title-group-container .user-row:hover input.checkbox[type="checkbox"] + .fake-input,
#assignee-list-container .list-title-group-container .item-container-unit:hover input.checkbox[type="checkbox"] + .fake-input {
    background: url("./Images/details/icon-iamdone.png") no-repeat center -16px;
}
#assignee-list-container .simple-list .list-title-group-container .user-row:hover input.checkbox[type="checkbox"] + .fake-input,
#assignee-list-container .simple-list .list-title-group-container .item-container-unit:hover input.checkbox[type="checkbox"] + .fake-input {
    background: url("./Images/details/icon-sl-iamdone.png") no-repeat center -16px;
}
#assignee-list-container .list-title-group-container .user-row:hover input.checkbox.disabled[type="checkbox"] + .fake-input,
#assignee-list-container .list-title-group-container .item-container-unit:hover input.checkbox.disabled[type="checkbox"] + .fake-input {
    background: transparent;
}
#assignee-list-container .list-title-group-container .item-container-unit .fake-input-container.checked input.checkbox + .fake-input,
#assignee-list-container .list-title-group-container .fake-input-container.checked input.checkbox + .fake-input,
#assignee-list-container .list-title-group-container .item-container-unit .fake-input-container.checked input.checkbox.disabled + .fake-input,
#assignee-list-container .list-title-group-container .fake-input-container.checked input.checkbox.disabled + .fake-input,
#assignee-list-container .list-title-group-container .fake-input-container.checked:hover input.checkbox[type="checkbox"] + .fake-input{
    background: url("./Images/details/icon-iamdone.png") no-repeat center 0;
}
#assignee-list-container .simple-list .list-title-group-container .item-container-unit .fake-input-container.checked input.checkbox + .fake-input,
#assignee-list-container .simple-list .list-title-group-container .fake-input-container.checked input.checkbox + .fake-input,
#assignee-list-container .simple-list .list-title-group-container .item-container-unit .fake-input-container.checked input.checkbox.disabled + .fake-input,
#assignee-list-container .simple-list .list-title-group-container .fake-input-container.checked input.checkbox.disabled + .fake-input {
    background: url("./Images/details/icon-sl-iamdone.png") no-repeat center 0;
}
#assignee-list-container .fake-input-container.done-checkbox:hover input.checkbox[type="checkbox"] + .fake-input {
    background: url("./Images/details/icon-iamdone.png") no-repeat center -32px;
}
.ac-viewtype-with-projects {
    display: inline-block;
    .fake-input-container {
        width: auto;
        float: none;
        padding: 2px 0 0px;
        height: 24px;
    }
    .fake-input,
    .project-head-text {
        float: left;
    }
    .project-head-text {
        font-size: 15px;
        margin: 0;
        font-weight: 600;
        color: #233b54;
        line-height: 18px;
    }
}
.checkbox-boxes {
    padding: 4px 10px;
    max-height: 300px;
    overflow-y: auto;

    .grid-checkbox {
        custom-checkbox {
            display: block;
            min-height: 28px;
        }

        .item-container-unit {
            line-height: 28px;
        }

        &.max-width-220 .item-container-unit {
            max-width: 220px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .fake-input-container {
            padding-top: 6px;
            padding-right: 4px;
        }
    }
}

.switcher {
    &.disabled {
        cursor: default;
        opacity: .8;
    }
    &.on {
        text-align: right;
        border-color: #3C74FD;
        background: linear-gradient(to top, #5183fc, #6490F9) !important;
        background: #6490F9;

        .switch {
            display: inline-block;
            border-color: #3C74FD;
            background-color: #fff;
        }
    }
    border: 1px solid #bbc1db;
    border-radius: 16px;
    width: 40px;
    padding: 2px;
    font-size: 0;
    background-color: #f6f7fa;
    cursor: pointer;

    .switch {
        border-radius: 50%;
        width: 16px;
        height: 16px;
        border:1px solid #bbc1db;
        background-color: #fff;
    }
}