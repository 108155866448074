html,
body {
    background:#f6f7fa;
    color: #666979;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', arial, helvetica, sans-serif;
    font-size: 15px;
    -webkit-font-smoothing: subpixel-antialiased !important;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
}
* {
    outline: none;
}
input::-ms-clear,
input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}
/**style placeholder **/
::-webkit-input-placeholder {
    color: #c7c9d4 !important;
}
/* Firefox 19+ */
::-moz-placeholder {
    color: #c7c9d4 !important;
    opacity: 1;
}
/* Firefox 18- */
:-moz-placeholder {
    color: #c7c9d4 !important;
    opacity: 1;
}
:-ms-input-placeholder {
    color: #c7c9d4 !important;
}
input.placeholder,
input.watermark {
    color: #c7c9d4 !important;
}
.eyed-input {
    position: relative;
}
.eyed-input .input-eye {
    width: 57px;
    height: 55px;
    position: absolute;
    right: 0;
    top: 0;
    background: url(./Images/elements/eye.svg) center center no-repeat;
    background-size: 30px auto;
    cursor: pointer;
    opacity: .33;
}
.eyed-input .input-eye:hover {
    opacity: .66;
}
.eyed-input input[type="password"],
.eyed-input input[type="text"] {
    padding-right: 32px;
    height: 55px;
}
a {
    color: #5183fc;
}
a:hover,
a:focus {
    color: #184CCA;
}

.hidden {
    display: none;
}
.content-login {
    width: 100%;
}

.center {
    text-align: center;
}

.content-inner {
    width: 100%;
}

.content-wrapper {
    width: 400px;
    margin: 0 auto;
}

.content-login h1 {
    font-weight: 300;
    font-size: 40px;
}

.login-logo-wrapper {
    margin-top: 20px;
    vertical-align: top;
    margin-bottom: 20px;
    width: 400px;
    position: relative;
}

.login-logo {
    border-radius: 3px;
    margin-right: 10px;
    max-width: 100px;
    max-height: 60px;
    vertical-align: middle;
    background: #fff;
    padding: 5px;
}
.login-logo-wrapper .name {
    font-size: 18px;
    position: absolute;
    color: #233b54;
    bottom: 0;
    right: 0;
}
.login-form {
    width:348px;
    padding:25px;
    border: 1px solid rgba(17,34,51,.15);
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(17,34,51,.2);
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.tablecell {
    display: table-cell;
}
.forgot-password,
.contact-us,
.login-additional-link,
.info-message a {
    text-decoration: none;
}

.button {
    font-family: 'Open Sans', sans-serif;
    height: 55px;
    line-height: normal;
    border-radius: 3px;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
    padding: 13px 0;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    outline: none;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    width: 348px;
    position: relative;
    border: 1px solid #3C74FD;
    background: #5183fc;
    background: -moz-linear-gradient(bottom, #5183fc 0%, #6490f9 100%);
    background: -webkit-linear-gradient(bottom, #5183fc 0%,#6490f9 100%);
    background: linear-gradient(to top, #5183fc 0%,#6490f9 100%);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.button.active-directory-icon{
    font-size: 18px;
    line-height: 29px;
    text-decoration: none;
    background: #fff;
    border: 1px solid #d2d6e7;
    color: #0079d6;
    background-size: inherit;
    padding-left: 40px;
}
.button.active-directory-icon:before{
    content: '';
    width: 33px;
    height: 33px;
    background: url(./Images/active-directory.svg) center center no-repeat;
    background-size: cover; 
    position: absolute;
    left: 20px;
    top: 10px;
    display: inline-block;
}
.separator{
    display: flex;
    text-transform: uppercase;
    align-items: center;
    font-size: 14px;
    color: #c3c7d9;
    margin: 30px 0;
}
.separator-line{
    flex: 1 0 auto;
    height: 1px;
    background: #c3c7d9;
}
.separator-text{
    padding: 0 10px;
}
.button.active-directory-icon:hover {
    border-color: #bbc1db;
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.8), 0px 1px 1px 0px rgba(144, 148, 169, 0.2);
    background: #fff
}
.button.active-directory-icon:active {
    background: linear-gradient(to top, #f2f3f9, #f9fafd);
    background-color: #f9fafd;
    border-color: #bbc1db;
    box-shadow: inset 0px 1px 1px 0px rgba(144, 148, 169, 0.2);
}
.button:hover {
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.06), 0px 1px 1px 0px rgba(144, 148, 169, 0.5);
}
.button:focus {
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.06), 0px 1px 1px 0px rgba(144, 148, 169, 0.5);
}
.button:focus:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 3px;
    border: 1px solid #fff;
    pointer-events: none;
}
.button:active,
.button.active {
    border: 1px solid #3871FB;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    background: #5082FB;
}
.button.disabled {
    cursor: default;
    pointer-events: none;
    background: #a8c1fe;
    border: 1px #a4bdfa solid;
}
.button.active-directory-icon.disabled {
    opacity: 0.66;
    background: #fff;
    border: 1px solid #d2d6e7;
}
.button.loading {
    background: #5183fc;
    border: 1px solid #497bf4;
    cursor: default;
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    background-image: url("./Images/loading-on-blue.gif");
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.input-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    display: block;
    width: 348px;
    font-weight: 600;
    padding: 13px;
    border-radius: 3px;
    border: 1px solid #c3c7d9;
    box-sizing: border-box;
    box-shadow: inset 0px 1px 1px 0px rgba(144, 148, 169, 0.2);
    color: #112233;
}
.password .input-text.full-width {
    width: 100%;
}
.password .input-text {
    padding-right: 57px;
    width: 270px;
}
#successMessage {
    margin-bottom: 20px;
}
.input-text:hover,
.password:hover .input-text {
    border-color: #9ca2bd;
}
.input-text:focus {
    border-color: #5183fc;
}

.inlineblock {
    display: inline-block;
}

.m-top-10 {
    margin-top: 10px;
}
.m-top-12 {
    margin-top: 12px;
}
#rememberMeFake {
    margin-right: 3px;
}
.m-top-14 {
    margin-top: 14px;
}
.m-top-20 {
    margin-top: 20px;
}
.rememberme {
    line-height: 16px;
    text-indent: 3px;
}

.wrapp_wrapp_msg {
    width: 400px;
    margin: 0 auto;
    text-align: left;
}
.wrapp_msg {
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
    margin-top: 20px;
    border: 1px solid #d2d6e7;
    border-radius: 3px;
    background: #fff;
    padding: 5px;
}
.attention {
    position: relative;
    font-size: 15px;
    padding-right: 10px;
}
.attention.error {
    border-radius: 3px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #FFE5E5;
    margin-bottom: 20px;
}
.attention div {
    position: absolute;
    background: #e55363;
    top: 0;
    bottom: 0;
    width: 5px;
    float: left;
    border-radius: 3px;
}
.attention.warning {
    padding-top: 7px;
    padding-bottom: 7px;
}
.attention.warning div {
    background: #FFCB00;
}
.attention span {
    display: block;
    font-size: 15px;
    margin-left: 15px;
}

.info-message {
    line-height: 1.7;
}
#password-change-rule,
#password-expired-note{
    margin-bottom: 20px;
    background: #fcf5de;
    color: #7c6a21;
}